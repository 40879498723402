import httpService from '../../../helpers/http.service'

export default {
    deleteHolder(id) {
        return httpService.delete(`holders/${id}`, );
    },
    getByHolder(id, params) {
        return httpService.get(`holders/${id}/enrollments`, {params});
    },
    get(id = null, params = {}) {
        return httpService.get(`enrollments${id ? '/' + id : '' }`, {params});

    },
    delete(id) {
      return   httpService.delete(`enrollments/${id}`);
    },
    clone(id) {
        return   httpService.post(`enrollments/${id}/clone`);
    },
    save(params, id = null) {
        if(id) {
            return httpService.put(`enrollments/${id}`, params);

        }
        return httpService.post(`enrollments`, params);
    },
    sync2Nbs(id) {
        return httpService.post(`enrollments/${id}/import`, {});
    },
    sync2NbsFix(id) {
        return httpService.post(`enrollments/${id}/fix-import`, {});
    }
}
