<template>
  <div v-if="canAccess('cobranzas_titulares')">
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
      <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <holder :holder="holderData" :edit="false" v-if="!loading"></holder>
    <div class="p-grid">
      <Fieldset legend="Matrículas" :toggleable="true">
        <ProgressBar mode="indeterminate" v-if="loading"/>
        <DataTable :value="enrollments" :filters="filters" scrollable>
          <template #header>
            <Toolbar class="p-col-12 p-mb-1">
              <template #left>
                <h5 class="p-mr-2">
                  Matrículas asociadas
                </h5>
              </template>
              <template #right>
                <Button label="Editar titular" icon="pi pi-user" class="p-button-info p-mr-2"
                        @click="editHolder = true, currentItem = null"/>
                <Button label="Regresar" class="p-button-warning p-mr-2"
                        @click="returnMain()"/>
              </template>
            </Toolbar>
          </template>
          <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field">
            <template #body="slotProps" v-if="col.field === 'cost' || col.field === 'balance'">
              {{ $filters.moneyFormat(slotProps.data[col.field]) }}
            </template>
          </Column>

          <Column header="Opciones"  headerStyle="width: 200px">
            <template #body="slotProps">
              <Button v-tooltip="'Beneficiarios'" icon="pi pi-user" class="p-button-rounded p-button-info  p-mr-2" @click="editStudent(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
      </Fieldset>
    </div>

    <Sidebar v-model:visible="studentSidebar" :baseZIndex="1000"  position="right" :style="'width:60%'">
      <h3>Estudiantes</h3>
      <students ref="studentList" v-if="currentItem" :enroll="currentItem" :edit="true"></students>
    </Sidebar>


    <Sidebar v-model:visible="editHolder" :baseZIndex="1000"  position="right" :style="'width:70%'" class="custombar">
      <h3>{{currentItem ? 'Editar titular': 'Nuevo titular'}}</h3>
      <holder ref="holderForm" :holder="holderData" :edit="true" :typeEmployees = typeEmployees  :typeHousing = typeHousing  @cancel="editHolder = false" @ok="getData"></holder>
      <div class="p-grid p-jc-end">
        <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="editHolder = false"/>
        <Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="saveHolder" />
      </div>
    </Sidebar>
  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>
<script>
import bouncer from "../../../../helpers/bouncer";
import holderService from '../../../enrollment/service/holder.service'
import service from '../../../enrollment/service/enrollment.service'
import {defineAsyncComponent} from "vue";
import {mapState} from "vuex";
import catalogService from "../../../core/service/catalog.service";

export default {
  mixins:[bouncer],
  components: {
    holder: defineAsyncComponent(() => import('../../../enrollment/components/holders/Holder')),
    students: defineAsyncComponent(() => import('../../../enrollment/components/students/StudentListOnly')),

  },
  data() {
    return {
      loading: false,
      editHolder: false,
      studentSidebar: false,
      contractSidebar: false,

      studentDialog: false,
      holderData: {},
      enrollments: [],
      columns: null,
      filters: {},
      currentItem: null,
      typeEmployees: [],
      typeHousing: [],
    }
  },

  mounted() {
    if(this.canAccess('cobranzas_titulares')){
      this.columns = [
        {field: 'sequential', header: 'N. Matrícula'},
        {field: 'registration', header: 'Fecha de registro'},
        {field: 'plan.name', header: 'Plan'},
        {field: 'cost', header: 'Valor'},
        {field: 'balance', header: 'Saldo'},
        {field: 'status', header: 'Estado'},
        {field: 'employee.name', header: 'Asesor'},
        {field: 'subsidiary.name', header: 'Filial'},
        // {field: 'registration_origin.description', header: 'Origen'}
      ];
      if (this.$route.params.id) {
        this.loading = true;
        const id = this.$route.params.id;
        holderService.get(id).then(x => {
          this.holderData = x.data;
          if (!this.country){
            if(this.holderData.country) {
              this.$store.dispatch('enroll/setCountry', this.holderData.country);
            } else {
              this.$router.push('/verification/holders')
            }
          }
          catalogService.get('holders/types/employee').then(x => {
            this.typeEmployees = [];
            Object.keys(x.data).forEach(k => {
              this.typeEmployees.push({value: k, label: x.data[k]});
            });
            this.typeEmployees = Object.values(this.typeEmployees)
          });
          catalogService.get('holders/types/housing').then(x => {
            this.typeHousing = [];
            Object.keys(x.data).forEach(k => {
              this.typeHousing.push({value: k, label: x.data[k]});
            });
          });
          service.getByHolder(this.holderData.id).then(x => {
            this.enrollments = x.data;
          }).catch(() => {
            this.enrollments = [];
          })
        }).finally(() => {
          this.loading = false;
        });
      }
    }

  },
  methods: {
    returnMain(){
      location.href='/#/raise-money/holders';
      window.location.reload(true)
    },


    saveHolder() {
      this.$refs.holderForm.$refs.holderForm.save().then(() => {
        this.getData()
      })
    },
    editStudent(ev) {

      if(ev.balance > 0 && ev.employee?.name!='ASESOR IMPORTACION') {
        this.$toast.add({
          severity: 'warn',
          summary: 'Pagos requeridos',
          detail: 'El valor total de la matrícula no ha sido registrado',
          life: this.$utils.toastLifeTime()
        });
      } else {
        this.currentItem =  ev;
        this.$nextTick(() => {
          this.studentSidebar = true;
        })
      }
    },

    saveEnroll() {
      const ev = this.$refs.enrollForm.$refs.enrollForm.save();
      if(!ev) {
        return
      }
      ev.then(() => {
        this.getData()
      }).catch(() => {
        this.loading = false
      })
    },
    getData() {
      this.loading = true;
      this.studentDialog = false;
      this.currentItem = null;
      service.getByHolder(this.holderData.id).then(x => {
        this.enrollments = x.data;
      }).catch(() => {
        this.enrollments = [];
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
    ...mapState('enroll', ['country']),
    ...mapState('auth', ['isAdmin']),
  }
}
</script>
<style lang="scss" scoped>
.p-progressbar {
  height: 1px;
}

.p-toolbar {
  margin-top: 10px;
  background-color: white;
}

.p-toolbar-group-right {
  width: 100%;
}


</style>
